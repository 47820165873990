@import "../../../styles/variables";

.copyrights {
  margin-top: 22px;
  p {
    color: $contrastText;
    font-size: 16px;
    font-weight: 700;
    line-height: 27px;
    position: relative;
    padding: 15px 0;
    display: inline-block;
    left: 50%;
    transform: translateX(-50%);
    &:before {
      display: block;
      content: "";
      width: 110%;
      height: 2px;
      background: $contrastText;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
