.footer-title {
  margin: 0;
  font-size: 16px;
  color: white;
}

.footer-title a {
    color: #FFF !important;
  }

footer {
  height: 56px !important;
  background-color: #555555 !important;
  padding: 0 !important;
}
