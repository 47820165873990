@import "../../../../styles/variables";

.categories-selection {
  .Filters {
    padding: 0 !important;
  }
  .seleziona-categoria {
    color: $text-primary;
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: bold;
    line-height: 27px;
  }
  .categorie-selezionat {
    color: $text-primary;
    font-family: "Open Sans";
    font-size: 16px;
    line-height: 24px;
    padding: 10px 0;
    span {
      color: #653399;
      font-family: "Open Sans";
      font-size: 16px;
      line-height: 24px;
    }
  }
}
