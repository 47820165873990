@import "../../styles/variables";

.item {
  background-color: white;
  transition: all 0.2s;
  cursor: pointer;
  border: 2px solid transparent;
  padding: 30px;
  &.wrapp {
    margin: 0 15px;
  }
  .item-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 330px;
    justify-content: center;
  }
  h4 {
    color: #555555;
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 21px;
    text-align: center;
  }
  img {
    margin-bottom: 30px;
    height: 180px;
  }
  .title {
    color: $text-primary;
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
  }
}
