.radio-wrapper {
    display: flex;
    cursor: pointer;
    align-items: center;
    user-select: none;
}

.outer-circle {
    width: 22px;
    height: 22px;
    background: #FFFFFF;
    border: 2px solid #EDF1F4;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    transition: all 0.1s linear;
}

.inner-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #1FBCE8;
    transition: all 0.1s linear;
}
.unselected {
    border: 2px solid #EDF1F4;
}
.unselected-circle {
    width: 0;
    height: 0;
}
.helper-text {
    font-family: Montserrat;
    font-size: 12px;
    line-height: 15px;
    color: #8C96A0;
}