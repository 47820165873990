@import "./variables";

body {
  margin: 0;
  font-family: "Open Sans" !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $text-primary;
  text-align: left;
  background-color: #fbfcfc !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}

.primary-bg {
  background: $primary_bg;
}

.access-bg {
  background-image: $access-bg;
  background-size: cover;
  .suggestion .description {
    a:hover {
      color: #898bd3 !important;
    }
  }
}

.pink-color {
  color: #8569d0 !important;
}

.dark-pink {
  color: #653399 !important;
}

.navbar {
  box-shadow: none;
}

label {
  height: 21px;
  width: 100%;
  color: #555555;
  font-size: 12px;
  line-height: 21px;
  margin-bottom: 0;
}

tbody {
  border: 1px solid $border;
}

a {
  color: $link-color !important;
}

a:hover {
  color: $link-hover !important;
}

.border-bottom {
  border-bottom: 2px solid $border !important;
}

.icon-add {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  background-color: #ff8800;
}

.pagination-arrows {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  line-height: 30px;
  text-align: center;
  list-style: none;
  background-color: rgb(255, 255, 255);
  border: 1px solid #e4dcee;
  border-radius: 4px;
  outline: 0px;
  cursor: pointer;
}

.full-screen {
  width: 100%;
  min-height: 100vh;
  &.centered-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.title {
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 50px 0;
  color: #555555;
  font-size: 25px;
  line-height: 27px;
  text-align: center;
}
.subtitle {
  margin: 0;
  color: #555555;
  font-family: "Open Sans";
  font-size: 20px;
  line-height: 27px;
  text-align: center;
}

.content {
  background: #fff;
  margin: 15px 0;
}

::placeholder {
  color: $light-grey !important;
  font-size: 14px;
  line-height: 21px;
}

.ant-radio-checked .ant-radio-inner {
  border-color: $radio-color !important;
}

.ant-radio-inner {
  border-color: $radio-color !important;
}

.ant-radio-inner::after {
  background-color: $radio-color !important;
}

.ant-checkbox-inner {
  border-color: $accent-color;
}

.checkbox.ant-checkbox-wrapper {
  font-size: 16px;
  line-height: 24px;
  color: $text-primary;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $accent-color;
  border-color: $accent-color;
}

a {
  color: inherit;
}

.fa,
.far,
.fas {
  cursor: pointer;
}

.list-group-flush {
  color: #495057;
}

.center {
  text-align: center;
}

.sidebar-fixed {
  left: 0;
  top: 0;
  height: 100vh;
  width: 270px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  z-index: 1050;
  background-color: #fff;
  padding: 1.5rem;
  padding-top: 0;
}

.flexible-navbar {
  transition: padding-left 0.5s;
  background-color: $navbar;
}

.footer-bg {
  background-color: $footer;
  padding: 40px 0;
}

.sidebar-fixed .logo-wrapper img {
  width: 100%;
  padding: 2.5rem;
  min-height: 180px;
}

.list-group-item {
  display: block !important;
  transition: background-color 0.3s;
}

.list-group-item:hover {
  color: #49505b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item:hover {
  color: #49505b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group .active {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

.card-title {
  font-size: 1.5rem;
  font-weight: 600;
}

.card-subtitle {
  font-size: 1.25rem;
  font-weight: 600;
}

.full {
  height: 70vh;
}

.language-select {
  width: 100%;
  height: 56px;
  padding: 0 10px;
  border-radius: 0px;
  border: 1px solid #ced4da;
  background-color: white;
}

.Select-control {
  background-color: white;
  border-radius: 0px;
  color: #333;
  cursor: default;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 56px;
  padding: 0 10px;
}

.Select-placeholder,
.Select--single > .Select-control .Select-value {
  bottom: 0;
  color: #aaa;
  left: 0;
  line-height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  right: 0;
  top: 13px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}

.Select-input {
  padding-left: 0px;
}

.Select-input > input {
  width: 100%;
  margin-top: 13px;
}

.bad-gateway-row {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 55%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.card .view.gradient-card-header {
  padding: 1rem 1rem;
  text-align: center;
}

.card h3,
.card.card-cascade h4 {
  margin-bottom: 0px;
}

.cascading-admin-card .admin-up {
  margin-left: 4%;
  margin-right: 4%;
  margin-top: -20px;
}

.cascading-admin-card .admin-up .fa {
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.2), 0 2px 13px 0 rgba(0, 0, 0, 0.19);
}

.cascading-admin-card .admin-up .fa {
  padding: 1.7rem;
  font-size: 2rem;
  color: #fff;
  text-align: left;
  margin-right: 1rem;
  border-radius: 3px;
}

.cascading-admin-card .admin-up .data {
  float: right;
  margin-top: 2rem;
  text-align: right;
}
.admin-up .data p {
  color: #999999;
  font-size: 12px;
}
.classic-admin-card .card-body {
  color: #fff;
  margin-bottom: 0;
  padding: 0.9rem;
}
.classic-admin-card .card-body p {
  font-size: 13px;
  opacity: 0.7;
  margin-bottom: 0;
}
.classic-admin-card .card-body h4 {
  margin-top: 10px;
}

.form-inline {
  flex-flow: unset;
}

.breadcrumb {
  margin: 0;
}

.activeClass .list-group-item {
  z-index: 5;
  color: #fff;
  border-color: #007bff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px !important;
  background-color: #007bff !important;
}

@media (max-width: 1199.98px) {
  .sidebar-fixed {
    display: none;
  }
  .flexible-content {
    padding-left: 0;
  }
  .flexible-navbar {
    padding-left: 10px;
  }

  #content {
    margin-left: 0px;
  }

  .page-footer {
    margin-left: 0px;
  }

  .card.cascading-admin-card {
    margin-top: 2.5rem;
  }
}

@media (max-width: 576px) {
  .card.cascading-admin-card {
    margin-top: 2.5rem;
  }

  #breadcrumb {
    flex-direction: column;
  }
}

#btn-logout {
  cursor: pointer;
}

.main-container {
  min-height: 100vh;
  position: relative;
  padding-bottom: 213px;
}

.main-container .page-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #8C96A0 !important;
}

.table {
  text-align: center;
  margin: 0 !important;
}

.fa-plus {
  color: white;
}

.table-head {
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  padding: 16px;
}

.ant-input {
  border-radius: 0px !important;
}

.personalized-margin-table .ant-table-tbody > .ant-table-row > td {
  border-bottom: 0;
}

.personalized-margin-table.ant-table-tbody > .ant-table-expanded-row > td {
  border-bottom: 1px solid #e8e8e8;
}

.table-container .offers-table .ant-spin-nested-loading th {
  color: #555555;
}

.table-container {
  .title {
    text-align: left;
    font-size: 24px;
    font-weight: 700;
    margin: 40px 0;
  }

  .Filters {
    margin-bottom: 30px;
    padding: 0 110px;

    .ant-select {
      width: 100%;
      height: 40px;
      border-color: $border;
      .ant-select-selection,
      .ant-select-selection__rendered {
        border: 0;
        height: 100%;
        line-height: 40px;

        .ant-select-selection__placeholder {
          color: #555555;
        }
      }
    }

    input {
      height: 40px;
      border-color: $border;
      border-radius: 0;
      border: 0;
    }

    ::placeholder {
      color: #555555 !important;
      font-size: 14px;
      line-height: 21px;
    }

    .ant-select-selection {
      border-color: $border;
      border-radius: 0;
    }

    .ant-input-group {
      input {
        border-color: $border;
        border-radius: 0;
      }
    }
  }

  .offers-table {
    .ant-spin-nested-loading {
      background: #fff;

      th {
        font-size: 18px;
        font-weight: 700;
        line-height: 27px;
      }

      td {
        i.fas {
          font-size: 16px;
        }
      }

      .ant-checkbox-inner {
        border-color: $accent-color2;
        opacity: 0.5;
      }

      .ant-checkbox-checked .ant-checkbox-inner {
        background: $accent-color2;
        opacity: 0.5;
      }

      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > td {
        border-color: $border;
      }
    }

    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
      background-color: $accent-color2;
      opacity: 0.5;
    }

    .ant-pagination.ant-table-pagination {
      width: 100%;
      text-align: center;
    }
  }

  .offers-table-background {
    .ant-spin-nested-loading {
      .ant-table-thead > tr > th {
        background-color: rgb(93, 72, 168);

        div > span {
          color: #fff;
          font-size: 16px;
          font-weight: "normal";
          font-family: "Open Sans";
          line-height: 24px;
          float: left;
        }

        div > button {
          height: 24px;
          width: 60.05px;
          color: #e08080;
          font-family: "Open Sans";
          font-size: 16px;
          font-weight: bold;
          line-height: 24px;
          border: none;
          background: none;
          float: right;
        }

        div > button:hover {
          cursor: pointer;
        }
      }

      .ant-table-tbody > tr > td {
        max-width: 185px;
      }
    }
  }

  .offers-table-none {
    .ant-table-body > table > thead > tr > th {
      border-bottom: none !important;
    }

    .ant-table-placeholder {
      display: none;
    }

    .ant-table-body > table > thead > tr > th {
      background-color: $modal-bg !important;
    }
  }
}

.ant-select-dropdown {
  border-color: $border !important;
  border-radius: 0 !important;

  .ant-select-dropdown-menu-item {
    border-color: $border !important;
    border-radius: 0 !important;
  }
}

.offer-table-row {
  background-color:  transparent !important;
  box-shadow: none !important;

  > td {
    padding: 10px 0!important;
    border-top: 10px solid transparent;
    box-shadow: none !important;
  }
}

.offer-detail {
  .actions {
    right: 20px;

    button {
      background: none;
      border: none;
      font-size: 20px;
      padding: 5px 10px;

      &.delete {
        right: 20px;
        color: $remove-icon;
      }
    }
  }
}

@-moz-document url-prefix() {
  .table-container .Filters input {
    padding: 10px;
  }
  .table-container .Filters .ant-input-group input {
    padding: 10px 0;
  }
}

.sc-bdVaJa {
  cursor: pointer;
}

.company-adv {
  .item {
    min-width: 280px;
  }
}

.hand {
  cursor: pointer;
}

.debug {
  [data-component] {
    color: darkorange;
    border-style: dotted;
    border-width: 1px;
    position: relative;
    padding: 20px 5px 5px;
    &:before {
      content: attr(data-component);
      position: absolute;
      top: 0;
      right: auto;
      background: white;
      color: inherit;
      z-index: 1;
      padding: 0 5px;
      font-size: 10px;
      letter-spacing: 1px;
      font-family: monospace;
      transform: translate(-50%, -50%);
      left: 50%;
      border-style: solid;
      border-width: 1px;
      border-color: inherit;
    }
    [data-component] {
      color: red;
      [data-component] {
        color: green;
        [data-component] {
          color: dodgerblue;
          [data-component] {
            color: plum;
          }
        }
      }
    }
  }
}