$primary_bg: linear-gradient(135deg, #332767 0%, #5c48a8 100%);

$access-bg: url("../assets/background.jpg");

$accent-color: #ff8800;
$accent-color2: #332767;
$link-color: #555555;
$accent-color-hover: #df7905;

$navbar: #332767;
$footer: #332767;

$modal-bg: #fbfcfc;

$contrastText: #fff;
$text-primary: #555555;
$link-hover: #898bd3;

$light-grey: #9b9b9b;

$border: #e4dcee;
$fields-border-color: #baa4d0;

$remove-icon: #e08080;

$radio-color: #7969b7;

$tab-height: 48px;
$tab-background: #ffffff;
$tab-item-color: #D4D4EF;
$tab-item-active-color: #5C48A8;
$tab-item-hover-color: #898BD3;
