.lang-switcher {
  position: absolute;
  top: 1em;
  right: 2em;
  color: #fff;
  a {
    color: #fff !important;
    display: inline-block;
    & + a:before {
      content: '';
      border-left: 1px solid #fff;
      margin: 0 10px;
    }
  }
}