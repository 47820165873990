.nav-item .nav-link {
  color: #8569d0 !important;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 700;
  line-height: 27px;
}

.nav-item .nav-link.active {
  color: #fff !important;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 700;
  line-height: 27px;
}

.nav-item .nav-link:hover {
  color: #ffffff !important;
}
