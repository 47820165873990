@import "../../../styles/variables";

.suggestion {
  text-align: center;
  color: $contrastText;
  margin-bottom: 28px;
  img {
    display: block;
    margin: 0 auto 34px auto;
  }
  .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    margin: 0 0 10px;
    color: inherit;
  }
  .description {
    font-size: 18px;
    line-height: 27px;
    display: inline-block;
    margin: 0 0 10px;
    a {
      color: white !important;
    }
  }
}
