.child-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: white;
  padding: 10px 0;
}

.flex-centr {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-centr .logo {
  width: 45px;
  height: 45;
  margin: 0px 40px 0 20px;
}

.flex-centr h4 {
  color: white;
  margin: 0;
  font-weight: 700;
}

.flex-centr p {
  color: white;
  margin: 0 20px 0 0;
}

.chat-block {
  display: flex;
  align-items: center;
}

.chat-block button {
  height: 45px;
  width: 45px;
  font-size: 20px;
  border-radius: 50%;
  border: 0;
  background: #ff8800;
  margin-left: 10px;
  cursor: pointer;
}
.chat-block button:focus {
  border: 0px;
  outline: 0;
}
