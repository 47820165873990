.secondary-button {
    color: #8C96A0;
    background-color: #F0F0F4;
}

.primary-button {
    color: white;
    background-color: #EF8634;
}

.custom-button {
    font-size: 14px;
    line-height: 19px;
    padding: 10px 16px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
}