@import "../../../../styles/variables";

.confirm-order {
  h1 {
    color: $text-primary;
    font-family: "Open Sans";
    font-size: 24px;
    font-weight: bold;
    line-height: 36px;
  }
  p {
    color: $text-primary;
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 21px;
  }
}
