.ant-badge-dot {
  z-index: 10;
  width: 6px;
  height: 6px;
  background: #f5222d;
  border-radius: 100%;
  box-shadow: 0 0 0 0 #fff;
  position: absolute;
  top: 3px;
  right: 3px;
}
