.rlv-c-filter__field {
max-width: 14%;
margin-right: 1rem;
}
.rlv-c-filter__field:last-child {
margin-right: 0 !important;
}

.table-container .Filters {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0 0 !important;
}

.col-1-2 {
    flex: 0 0 12.666667%;
    max-width: 12.666667%;
}
td.property-name {
    overflow: hidden;
    text-overflow: ellipsis;
}
.btn.primary-outline {
    border: 2px solid #EF8634;
    color: #EF8634;    
    white-space: nowrap;
    height: 40px;
    padding: 0 1rem;
}