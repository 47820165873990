.select-wrapper {

}

.select-header {
    background: #FFFFFF;
    border: 2px solid #F0F0F4;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: Montserrat;
    font-size: 12px;
    line-height: 15px;
    color: #8C96A0;
    padding: 14px;
    cursor: pointer;

}

.select-options-container {
    position: absolute;
    width: 100%;
    z-index: 999;
    margin-top: 8px;
    padding: 0;
    background: #ffffff;
    color: #8C96A0;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    border: 2px solid #F0F0F4;
    box-sizing: border-box;
    border-radius: 8px;
}

.select-option {
    list-style: none;
    padding: 4px 14px;
}

.select-option:hover {
    background: #E5E5E5;;
}


.select-label {
    font-family: Montserrat;
    font-size: 12px;
    line-height: 15px;
    color: #8C96A0;
    margin-bottom: 4px;
    margin-top: 0;
}

.w-33 {
    width: 33%;
}

.error {
    border-color: #dc3545;
}