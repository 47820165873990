@import "../../styles/variables";

.main {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  .suggestion {
    color: #fff;
  }
  .card-wrapp {
    color: #9a9a9a;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .item:hover {
      -webkit-transform: scale(1.07);
      -ms-transform: scale(1.07);
      -moz-transform: scale(1.07);
      -o-transform: scale(1.07);
      transform: scale(1.07);
      transition: all 0.2s ease-in-out;
    }

    .item img {
      margin-bottom: 30px;
      height: auto;
      width: 120px;
    }
  }
}
