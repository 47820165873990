.title {
    font-weight: bold;
    font-size: 32px;
    line-height: 44px;
    text-align: left;
    color: #333333;
    margin: 0;
}

.description {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 32px;
    color: #333333;
    font-weight: 400;
}

.label {
    font-family: Montserrat;
    font-size: 12px;
    color: #8C96A0;
}

#react-select-objectives--value {
    height: 100%;
    display: flex;
    align-items: center;
}
.description-input::placeholder {
    font-family: Montserrat;
    font-size: 12px;
    line-height: 15px;
    color: #8C96A0;
}
.description-input {
    border: 2px solid #F0F0F4;
    border-radius: 8px !important;
    font-family: Montserrat;
    font-size: 12px;
    line-height: 15px;
    color: #8C96A0;
}

.sub-title {
    margin-top: 40px;
    margin-bottom: 0;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: #333333;
}
input[name=articleWriting] + label {
    font-family: Montserrat;
    font-size: 12px;
    line-height: 15px;
    color: #8C96A0;
    display: flex;
    align-items: center;
}
