@import "../../../../styles/variables";

.content.property {
  padding: 30px;
  position: relative;
  border: 2px solid #f0ecf5;

  .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
    margin: 0;
  }

  .label {
    font-size: 16px;
    font-weight: 700;
    line-height: 27px;
    margin-right: 8px;
  }

  .ant-row {
    margin-bottom: 15px;
  }

  .actions {
    position: absolute;
    right: 20px;
    top: 25px;

    button {
      background: none;
      border: none;
      font-size: 20px;
      padding: 5px 10px;

      &.delete {
        color: $remove-icon;
      }
    }
  }
}

.fa-plus {
  color: white;
}

.offer-detail {
  .actions {
    right: 20px;

    button {
      background: none;
      border: none;
      font-size: 20px;
      padding: 5px 10px;

      &.delete {
        right: 20px;
        color: $remove-icon;
      }
    }
  }
}

@-moz-document url-prefix() {
  .table-container .Filters input {
    padding: 10px;
  }

  .otable-container .Filters .ant-input-group input {
    padding: 10px 0;
  }
}
