.input {
  border: 2px solid #F0F0F4;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px;
  font-size: 12px;
  line-height: 15px;
  height: 47px;
  color: #8C96A0;
  font-family: Montserrat;
}
.input::placeholder {
  font-size: 12px;
  line-height: 15px;
  font-family: Montserrat;
}

