@import "../../../styles/variables";

.wrapp-form {
  width: 50%;
  margin: auto;
  background: #fff;
  padding: 40px;
  max-width: 540px;
  .suggestion {
    text-align: center;
    color: $light-grey;
    span {
      color: $light-grey;
      font-weight: 500;
      padding-left: 5px;
      cursor: pointer;
    }
  }
  label {
    margin: 0;
    font-size: 12px;
    color: #555555;
  }
  .dimenticato {
    margin-bottom: 15px;
    color: #868686;
    font-weight: 500;
  }
  button {
    margin-top: 30px;
    width: 100%;
    height: 56px;
    padding: 14px 0;
    cursor: pointer;
    transition: 0.2s;
    color: white;
    background: $accent-color;
    transition: all 0.2s;
    &:hover {
      background: $accent-color-hover;
    }
  }
  .lable-bold {
    color: #555555;
    font-size: 13px;
    font-weight: 550;
    line-height: 27px;
  }
}
