@import "../../../styles/variables";

button.primary {
  border: 0;
  border-radius: 4px;
  height: 56px;
  cursor: pointer;
  color: $contrastText;
  background: $accent-color;
  padding: 15px;
  font-size: 18px;
  min-width: 160px;
  transition: 0.3s;
  a {
    color: $contrastText;
    padding: 15px;
    display: block;
  }
  &:hover {
    background: $accent-color-hover;
  }
}

button.default {
  padding: 10px 15px;
  width: calc(100% - 10px);
  border: none;
  outline: none;
  font-size: 12px;
  line-height: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;
  i {
    color: $accent-color2;
  }
}

button.BackButton {
  padding: 10px 0 10px 0;
  border: none;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $accent-color2;
  background: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    color: $link-hover;
  }
  i {
    color: $accent-color2;
    margin-right: 15px;
  }
}

button.smallButton {
  border: 0;
  border-radius: 0;
  height: 40px;
  cursor: pointer;
  color: white !important;
  background: $accent-color;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  min-width: 160px;
  transition: 0.3s;
  i {
    color: white !important;
  }
  a {
    color: $contrastText;
    padding: 15px;
    display: block;
  }
  &:hover {
    background: $accent-color-hover;
  }
}
